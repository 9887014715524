import type { Typographies } from '../types';

export const fontFamily = {
  default: 'var(--font-family-default)',
  code: 'var(--font-family-code)',
};

export const typography: Typographies = {
  'display-1': {
    fontWeight: 600,
    fontSize: '3.75rem',
    lineHeight: '127%',
  },
  'display-2': {
    fontWeight: 600,
    fontSize: '2.5rem',
    lineHeight: '130%',
  },
  'display-3': {
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: '129%',
  },
  'display-4': {
    fontWeight: 400,
    fontSize: '1.3125rem',
    lineHeight: '152.4%',
  },
  'heading-1': {
    fontWeight: 600,
    fontSize: '1.523rem',
    lineHeight: '139%',
  },
  'heading-2': {
    fontWeight: 600,
    fontSize: '1.353rem',
    lineHeight: '139%',
  },
  'heading-3': {
    fontWeight: 600,
    fontSize: '1.1875rem',
    lineHeight: '152%',
  },
  'body-1': {
    fontWeight: 400,
    fontSize: '1.065rem',
    lineHeight: '155.5%',
  },
  'body-2': {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '141.17%',
  },
  'body-3': {
    fontWeight: 400,
    fontSize: '0.9375rem',
    lineHeight: '150%',
  },
  'body-4': {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '150%',
  },
  'label-1': {
    fontWeight: 500,
    fontSize: '1.065rem',
    lineHeight: '155.5%',
  },
  'label-2': {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '141.17%',
  },
  'label-3': {
    fontWeight: 500,
    fontSize: '0.9375rem',
    lineHeight: '150%',
  },
  'label-4': {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '150%',
  },
  'caption-1': {
    fontWeight: 500,
    fontSize: '0.8125rem',
    lineHeight: '142.86%',
  },
  'caption-2': {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '123.1%',
  },
  'caption-3': {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '133.33%',
  },
  'caption-4': {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '133.33%',
  },
  'snippet-1': {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '141.18%',
  },
  'snippet-2': {
    fontWeight: 400,
    fontSize: '0.882rem',
    lineHeight: '133.33%',
  },
  'snippet-3': {
    fontWeight: 400,
    fontSize: '0.8125rem',
    lineHeight: '123.08%',
  },
};

export const typographyExtra = {
  'header-title': {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '150%',
  },
  'body-title': {
    fontWeight: 600,
    fontSize: '1.1875rem',
    lineHeight: '147.37%',
  },
  'body-subtitle': {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '150%',
  },
  'body-label-default': {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '150%',
  },
  'body-label-compact': {
    fontWeight: 500,
    fontSize: '0.9375rem',
    lineHeight: '133.33%',
  },
};
