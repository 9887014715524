export const BrandSleep800 = '#0e1438';
export const BrandSleep300 = '#b3b0d1';
export const BrandSleep200 = '#e9e9f2';
export const BrandSleep100 = '#F4F6FC';
export const BrandThink500 = '#cbc1f6';
export const BrandThink800 = '#372680';
export const BrandGo500 = '#76fb8f';
export const BrandGo800 = '#0B857D';
export const Gray0 = '#ffffff';
export const Gray25 = '#f7f7f7';
export const Gray50 = '#f7f7f7';
export const Gray100 = '#e2e2e2';
export const Gray150 = '#d4d4d4';
export const Gray200 = '#c6c6c6';
export const Gray300 = '#a9a9a9';
export const Gray400 = '#8d8d8d';
export const Gray500 = '#707070';
export const Gray600 = '#5a5a5a';
export const Gray700 = '#444444';
export const Gray800 = '#2d2d2d';
export const Gray825 = '#242424';
export const Gray850 = '#1E1E1E';
export const Gray875 = '#1A1A1A';
export const Gray900 = '#161616';
export const Gray950 = '#0F0F0F';
export const Gray1000 = '#000000';
export const Red25 = '#fffefd';
export const Red50 = '#FFF2F0';
export const Red100 = '#edd0ce';
export const Red200 = '#e5a19d';
export const Red300 = '#d9726c';
export const Red400 = '#cc433b';
export const Red500 = '#bf140a';
export const Red600 = '#991008';
export const Red700 = '#730c06';
export const Red800 = '#5e1e18';
export const Yellow25 = '#fffefd';
export const Yellow50 = '#fff8eb';
export const Yellow100 = '#fff2d9';
export const Yellow200 = '#fee5b5';
export const Yellow300 = '#ffd582';
export const Yellow400 = '#ffc759';
export const Yellow500 = '#ffa617';
export const Yellow600 = '#f28900';
export const Yellow700 = '#ba5d00';
export const Yellow800 = '#9b4e00';
export const Yellow900 = '#694a1a';
export const Green25 = '#f5fefd';
export const Green50 = '#e9f5f1';
export const Green100 = '#cee6de';
export const Green200 = '#9ecebe';
export const Green300 = '#6db59d';
export const Green400 = '#3d9d7d';
export const Green500 = '#0c845c';
export const Green600 = '#0a6a4a';
export const Green700 = '#074f37';
export const Green800 = '#053525';
export const Blue25 = '#f5f3fe';
export const Blue50 = '#edf3fc';
export const Blue100 = '#cee2f9';
export const Blue200 = '#9ec5f3';
export const Blue300 = '#6da7ee';
export const Blue400 = '#3d8ae8';
export const Blue500 = '#0c6de2';
export const Blue600 = '#0a57b5';
export const Blue700 = '#074188';
export const Blue800 = '#0a305f';
export const Purple25 = '#f5f2fc';
export const Purple50 = '#F5F2FC';
export const Purple100 = '#ebe7fb';
export const Purple200 = '#c3b6f3';
export const Purple300 = '#9D8DEE';
export const Purple400 = '#7A6AE1';
export const Purple500 = '#4a24db';
export const Purple600 = '#3a1caa';
export const Purple700 = '#29147a';
export const Purple800 = '#190c49';
export const Darkblue50 = '#e4e6ec';
export const Darkblue100 = '#bbc0d1';
export const Darkblue200 = '#9098b2';
export const Darkblue300 = '#677194';
export const Darkblue400 = '#4a5580';
export const Darkblue500 = '#2c3b6d';
export const Darkblue600 = '#273466';
export const Darkblue700 = '#1f2c5b';
export const Darkblue800 = '#18234f';
export const Darkblue900 = '#0e1438';

/// Static colors
export const StaticBlack = '#000000';
export const StaticDimGray = '#444444';
export const StaticDarkGray = '#707070';
export const StaticLightGray = '#c6c6c6';
export const StaticWhite = '#ffffff';
export const StaticSleep = '#0e1438';
export const StaticThink = '#cbc1f6';
export const StaticGo = '#76fb8f';
