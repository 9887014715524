import type { CountryCode, CountryCode3 } from '@onefootprint/types';

export type CountryRecord = {
  value: CountryCode;
  value3: CountryCode3;
  label: string;
  passport: boolean;
  idCard: boolean;
  driversLicense: boolean;
  visa: boolean;
  workPermit: boolean;
  residenceDocument: boolean;
  passportCard: boolean;
  voterIdentification: boolean;
};

export const COUNTRIES: CountryRecord[] = [
  {
    label: 'United States of America',
    value: 'US',
    value3: 'USA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Afghanistan',
    value: 'AF',
    value3: 'AFG',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Albania',
    value: 'AL',
    value3: 'ALB',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Algeria',
    value: 'DZ',
    value3: 'DZA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'American Samoa',
    value: 'AS',
    value3: 'ASM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Andorra',
    value: 'AD',
    value3: 'AND',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Angola',
    value: 'AO',
    value3: 'AGO',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Antigua and Barbuda',
    value: 'AG',
    value3: 'ATG',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Argentina',
    value: 'AR',
    value3: 'ARG',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Armenia',
    value: 'AM',
    value3: 'ARM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Australia',
    value: 'AU',
    value3: 'AUS',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Austria',
    value: 'AT',
    value3: 'AUT',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
    value3: 'AZE',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Bahamas',
    value: 'BS',
    value3: 'BHS',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Bahrain',
    value: 'BH',
    value3: 'BHR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Bangladesh',
    value: 'BD',
    value3: 'BGD',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Barbados',
    value: 'BB',
    value3: 'BRB',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Belarus',
    value: 'BY',
    value3: 'BLR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Belgium',
    value: 'BE',
    value3: 'BEL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Belize',
    value: 'BZ',
    value3: 'BLZ',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Benin',
    value: 'BJ',
    value3: 'BEN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Bermuda',
    value: 'BM',
    value3: 'BMU',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Bolivia',
    value: 'BO',
    value3: 'BOL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
    value3: 'BIH',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Botswana',
    value: 'BW',
    value3: 'BWA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Brazil',
    value: 'BR',
    value3: 'BRA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN',
    value3: 'BRN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Bulgaria',
    value: 'BG',
    value3: 'BGR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
    value3: 'BFA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Burundi',
    value: 'BI',
    value3: 'BDI',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Cambodia',
    value: 'KH',
    value3: 'KHM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Cameroon',
    value: 'CM',
    value3: 'CMR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Canada',
    value: 'CA',
    value3: 'CAN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Cape Verde',
    value: 'CV',
    value3: 'CPV',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Cayman Islands',
    value: 'KY',
    value3: 'CYM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Chad',
    value: 'TD',
    value3: 'TCD',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Chile',
    value: 'CL',
    value3: 'CHL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'China',
    value: 'CN',
    value3: 'CHN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Colombia',
    value: 'CO',
    value3: 'COL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Congo (Democratic Republic)',
    value: 'CD',
    value3: 'COD',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Congo (Republic of)',
    value: 'CG',
    value3: 'COG',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Costa Rica',
    value: 'CR',
    value3: 'CRI',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Côte d’Ivoire',
    value: 'CI',
    value3: 'CIV',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Croatia',
    value: 'HR',
    value3: 'HRV',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Cuba',
    value: 'CU',
    value3: 'CUB',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Cyprus',
    value: 'CY',
    value3: 'CYP',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Czech Republic',
    value: 'CZ',
    value3: 'CZE',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Denmark',
    value: 'DK',
    value3: 'DNK',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Djibouti',
    value: 'DJ',
    value3: 'DJI',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Dominica',
    value: 'DM',
    value3: 'DMA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Dominican Republic',
    value: 'DO',
    value3: 'DOM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Ecuador',
    value: 'EC',
    value3: 'ECU',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Egypt',
    value: 'EG',
    value3: 'EGY',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'El Salvador',
    value: 'SV',
    value3: 'SLV',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Guinea',
    value: 'GQ',
    value3: 'GNQ',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Eritrea',
    value: 'ER',
    value3: 'ERI',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Estonia',
    value: 'EE',
    value3: 'EST',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Eswatini (Swaziland)',
    value: 'SZ',
    value3: 'SWZ',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Ethiopia',
    value: 'ET',
    value3: 'ETH',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Fiji',
    value: 'FJ',
    value3: 'FJI',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Finland',
    value: 'FI',
    value3: 'FIN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'France',
    value: 'FR',
    value3: 'FRA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Gabon',
    value: 'GA',
    value3: 'GAB',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Gambia',
    value: 'GM',
    value3: 'GMB',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Georgia',
    value: 'GE',
    value3: 'GEO',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Germany',
    value: 'DE',
    value3: 'DEU',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Ghana',
    value: 'GH',
    value3: 'GHA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Greece',
    value: 'GR',
    value3: 'GRC',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Grenada',
    value: 'GD',
    value3: 'GRD',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Guam',
    value: 'GU',
    value3: 'GUM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Guatemala',
    value: 'GT',
    value3: 'GTM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Guinea',
    value: 'GN',
    value3: 'GIN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
    value3: 'GNB',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Guyana',
    value: 'GY',
    value3: 'GUY',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Haiti',
    value: 'HT',
    value3: 'HTI',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'VA',
    value3: 'VAT',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Honduras',
    value: 'HN',
    value3: 'HND',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Hong Kong',
    value: 'HK',
    value3: 'HKG',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Hungary',
    value: 'HU',
    value3: 'HUN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Iceland',
    value: 'IS',
    value3: 'ISL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'India',
    value: 'IN',
    value3: 'IND',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Indonesia',
    value: 'ID',
    value3: 'IDN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Iran',
    value: 'IR',
    value3: 'IRN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Iraq',
    value: 'IQ',
    value3: 'IRQ',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Ireland',
    value: 'IE',
    value3: 'IRL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Israel',
    value: 'IL',
    value3: 'ISR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Italy',
    value: 'IT',
    value3: 'ITA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Jamaica',
    value: 'JM',
    value3: 'JAM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Japan',
    value: 'JP',
    value3: 'JPN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Jordan',
    value: 'JO',
    value3: 'JOR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
    value3: 'KAZ',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Kenya',
    value: 'KE',
    value3: 'KEN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Kosovo',
    value: 'KR',
    value3: 'KOR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Kuwait',
    value: 'KW',
    value3: 'KWT',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
    value3: 'KGZ',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Laos',
    value: 'LA',
    value3: 'LAO',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Latvia',
    value: 'LV',
    value3: 'LVA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Lebanon',
    value: 'LB',
    value3: 'LBN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Lesotho',
    value: 'LS',
    value3: 'LSO',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Liberia',
    value: 'LR',
    value3: 'LBR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Libya',
    value: 'LY',
    value3: 'LBY',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
    value3: 'LIE',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Lithuania',
    value: 'LT',
    value3: 'LTU',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Luxembourg',
    value: 'LU',
    value3: 'LUX',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Macao',
    value: 'MO',
    value3: 'MAC',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Macedonia ',
    value: 'MK',
    value3: 'MKD',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Madagascar',
    value: 'MG',
    value3: 'MDG',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Malawi',
    value: 'MW',
    value3: 'MWI',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Malaysia',
    value: 'MY',
    value3: 'MYS',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Maldives',
    value: 'MV',
    value3: 'MDV',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Mali',
    value: 'ML',
    value3: 'MLI',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Malta',
    value: 'MT',
    value3: 'MLT',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
    value3: 'MHL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Mauritius',
    value: 'MU',
    value3: 'MUS',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Mexico',
    value: 'MX',
    value3: 'MEX',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Micronesia (Federal States of)',
    value: 'FM',
    value3: 'FSM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Moldova',
    value: 'MD',
    value3: 'MDA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Monaco',
    value: 'MC',
    value3: 'MCO',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Mongolia',
    value: 'MN',
    value3: 'MNG',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Montenegro',
    value: 'ME',
    value3: 'MNE',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Montserrat',
    value: 'MS',
    value3: 'MSR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Morocco',
    value: 'MA',
    value3: 'MAR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Mozambique',
    value: 'MZ',
    value3: 'MOZ',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Myanmar',
    value: 'MM',
    value3: 'MMR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Namibia',
    value: 'NA',
    value3: 'NAM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Nepal',
    value: 'NP',
    value3: 'NPL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Netherlands',
    value: 'NL',
    value3: 'NLD',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Netherlands Antilles',
    value: 'AN',
    value3: 'ANT',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'New Zealand',
    value: 'NZ',
    value3: 'NZL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Nicaragua',
    value: 'NI',
    value3: 'NIC',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Niger',
    value: 'NE',
    value3: 'NER',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Nigeria',
    value: 'NG',
    value3: 'NGA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'North Korea',
    value: 'NF',
    value3: 'NFK',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
    value3: 'MNP',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Norway',
    value: 'NO',
    value3: 'NOR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Oman',
    value: 'OM',
    value3: 'OMN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Pakistan',
    value: 'PK',
    value3: 'PAK',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Palau',
    value: 'PW',
    value3: 'PLW',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Palestine (State of)',
    value: 'PS',
    value3: 'PSE',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Panama',
    value: 'PA',
    value3: 'PAN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
    value3: 'PNG',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Paraguay',
    value: 'PY',
    value3: 'PRY',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Peru',
    value: 'PE',
    value3: 'PER',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Philippines',
    value: 'PH',
    value3: 'PHL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Poland',
    value: 'PL',
    value3: 'POL',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Portugal',
    value: 'PT',
    value3: 'PRT',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
    value3: 'PRI',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Qatar',
    value: 'QA',
    value3: 'QAT',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Romania',
    value: 'RO',
    value3: 'ROU',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Russian Federation',
    value: 'RU',
    value3: 'RUS',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Rwanda',
    value: 'RW',
    value3: 'RWA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KN',
    value3: 'KNA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Saint Lucia',
    value: 'LC',
    value3: 'LCA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
    value3: 'VCT',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Samoa',
    value: 'WS',
    value3: 'WSM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'San Marino',
    value: 'SM',
    value3: 'SMR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Sao Tome and Principe',
    value: 'ST',
    value3: 'STP',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
    value3: 'SAU',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Senegal',
    value: 'SN',
    value3: 'SEN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Serbia',
    value: 'RS',
    value3: 'SRB',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Seychelles',
    value: 'SC',
    value3: 'SYC',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
    value3: 'SLE',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Singapore',
    value: 'SG',
    value3: 'SGP',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Sint Maarten',
    value: 'SX',
    value3: 'SXM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Slovakia',
    value: 'SK',
    value3: 'SVK',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Slovenia',
    value: 'SI',
    value3: 'SVN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Solomon Islands',
    value: 'SB',
    value3: 'SLB',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Somalia',
    value: 'SO',
    value3: 'SOM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'South Africa',
    value: 'ZA',
    value3: 'ZAF',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'South Korea',
    value: 'GS',
    value3: 'SGS',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'South Sudan',
    value: 'SS',
    value3: 'SSD',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Spain',
    value: 'ES',
    value3: 'ESP',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
    value3: 'LKA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Sudan',
    value: 'SD',
    value3: 'SDN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Suriname',
    value: 'SR',
    value3: 'SUR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Sweden',
    value: 'SE',
    value3: 'SWE',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Switzerland',
    value: 'CH',
    value3: 'CHE',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Syria',
    value: 'SY',
    value3: 'SYR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Taiwan',
    value: 'TW',
    value3: 'TWN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
    value3: 'TJK',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Tanzania',
    value: 'TZ',
    value3: 'TZA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Thailand',
    value: 'TH',
    value3: 'THA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Togo',
    value: 'TG',
    value3: 'TGO',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Tonga',
    value: 'TO',
    value3: 'TON',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TT',
    value3: 'TTO',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Tunisia',
    value: 'TN',
    value3: 'TUN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Turkey',
    value: 'TR',
    value3: 'TUR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
    value3: 'TKM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Tuvalu',
    value: 'TV',
    value3: 'TUV',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Uganda',
    value: 'UG',
    value3: 'UGA',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Ukraine',
    value: 'UA',
    value3: 'UKR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
    value3: 'ARE',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'United Kingdom',
    value: 'GB',
    value3: 'GBR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UM',
    value3: 'UMI',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Uruguay',
    value: 'UY',
    value3: 'URY',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
    value3: 'UZB',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Vanuatu',
    value: 'VU',
    value3: 'VUT',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Venezuela',
    value: 'VE',
    value3: 'VEN',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Vietnam',
    value: 'VN',
    value3: 'VNM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
    value3: 'VIR',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Yemen',
    value: 'YE',
    value3: 'YEM',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Zambia',
    value: 'ZM',
    value3: 'ZMB',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
    value3: 'ZWE',
    passport: true,
    idCard: true,
    driversLicense: true,
    visa: true,
    workPermit: true,
    residenceDocument: true,
    passportCard: true,
    voterIdentification: true,
  },
];

export const [DEFAULT_COUNTRY] = COUNTRIES;

export const BANNED_COUNTRIES = [];

export const COUNTRIES_WITH_STATES: CountryCode[] = [
  'US',
  'AU',
  'AT',
  'BR',
  'DE',
  'IN',
  'MY',
  'MX',
  'FM',
  'MM',
  'NZ',
  'NG',
  'PW',
  'SS',
];

export const COUNTRIES_WITH_PROVINCES: CountryCode[] = [
  'AF',
  'DZ',
  'AO',
  'AM',
  'AR',
  'TR',
  'BG',
  'BI',
  'CA',
  'KH',
  'CN',
  'CR',
  'CG',
  'CU',
  'DO',
  'EC',
  'GQ',
  'GA',
  'ID',
  'LA',
  'NP',
  'PK',
  'SL',
  'ZA',
  'SB',
  'LK',
  'TO',
  'VU',
  'VN',
  'ZM',
  'ZW',
];

export const US_TERRITORY_COUNTRY_CODES = ['AS', 'GU', 'MP', 'PR', 'VI'];

export const getCountryNameFromCode = (countryCode: CountryCode) =>
  COUNTRIES.find(country => country.value === countryCode)?.label;
